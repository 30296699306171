<template>
  <ValidationObserver v-slot="{ invalid }">
    <div
      class="border rounded-3xl shadow-xl py-8 w-full sm:w-4/5 lg:w-3/5 grid justify-items-center mx-auto"
    >
      <div class="w-3/4 lg:w-1/2">
        <!-- <ValidationProvider name="username" rules="required">
                    <div class="flex">
                        <font-awesome-icon class="input-field-icon" icon="fa-solid fa-user" />
                        <input v-model="form.username" placeholder="Phone number or email" class="input-field" />
                    </div>
                </ValidationProvider> -->
        <ValidationProvider
          name="username"
          :rules="{ required: true, regex: /^[0-9]+$/, min: 2 }"
        >
          <div class="flex">
            <font-awesome-icon
              class="input-field-icon"
              icon="fa-solid fa-mobile-screen-button"
            />
            <vue-country-code
              @onSelect="selectCountry"
              defaultCountry="ID"
              enabledCountryCode
              :enabledFlags="false"
              :onlyCountries="['MY', 'AU', 'ID', 'PH', 'SG', 'TH', 'DE']"
              style="border: none"
            >
            </vue-country-code>
            <div class="text-primary text-2xl">|</div>
            <input
              v-model="form.username"
              placeholder="Phone number"
              class="input-field"
              @keypress.enter="validateLogin"
            />
          </div>
        </ValidationProvider>
        <ValidationProvider class="block mt-6" name="password" rules="required">
          <div class="flex">
            <font-awesome-icon
              class="input-field-icon"
              icon="fa-solid fa-lock"
            />
            <input
              v-model="form.password"
              :type="showPassword ? 'text' : 'password'"
              placeholder="Password"
              @keypress.enter="validateLogin"
              class="input-field"
              pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
            />
            <div
              v-on:click="showPassword = !showPassword"
              class="cursor-pointer text-primary flex items-center"
            >
              <font-awesome-icon v-if="showPassword" icon="fa-solid fa-eye" />
              <font-awesome-icon v-else icon="fa-solid fa-eye-slash" />
            </div>
          </div>
          <div
            class="font-semibold text-sm text-right mt-2"
          >
            <span class="cursor-pointer" @click="$router.push({ name: 'ForgotPassword' })">Forgot password ?</span>
          </div>
        </ValidationProvider>
      </div>
    </div>
    <div class="flex justify-center mt-8">
      <button @click="validateLogin" class="button-submit text-lg px-20">
        Login
      </button>
    </div>
    <div class="text-center text-sm mt-4">
      <span class="font-medium">Don't have an account ?</span>
      <span
        @click="$router.push({ name: 'Register' })"
        class="cursor-pointer font-bold"
      >
        Sign Up</span
      >
    </div>
  </ValidationObserver>
</template>

<script>
export default {
  name: "Login",

  data() {
    return {
      form: { username: null, password: null },
      showPassword: false,
      dialCode: 62,
      formattedPhone: null,
    };
  },

  created() {
  },

  methods: {
    selectCountry({ dialCode }) {
      this.dialCode = dialCode;
    },
    validateLogin() {
      if (this.form.username == null || this.form.username == "") {
        this.$toasted.global.error("Phone number cannot be empty");
        return;
      }

      if (this.form.password == null || this.form.password == "") {
        this.$toasted.global.error("Password cannot be empty");
        return;
      }

      // if (!this.form.password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/)) {
      //   this.$toasted.global.error(
      //     "Password must contain 1 uppercase, 1 lowercase, 1 number and minimum 8 character"
      //   );
      //   return;
      // }

      this.login();
    },
    async login() {
      this.$store.commit("setOverlayLoading", true);

      if (this.dialCode == 62) {
        if (this.form.username.slice(0, 1) == "0") {
          this.formattedPhone = this.form.username;
        } else {
          this.formattedPhone = "0" + this.form.username;
        }
      } else {
        this.formattedPhone = this.dialCode + this.form.username;
      }

      try {
        const user = await this.$http
          .post(`${this.$apiTripweWebV2}/auth/login`, {
            phone: this.form.username,
            password: this.form.password,
            phone_country_code: this.dialCode,
          });
          // .catch(function (error) {
          //   if (error.response) {
          //     var errData = error.response.data;
          //     if (errData.status == "400") {
          //       throw errData.message;
          //     }
          //   }
          // });

        if (user.data.status == "400") {
          // throw 'Phone number not registered';
          throw user.data?.message ?? "User not found";
        }

        // if (user.data.result.error == "4002") {
        //     throw 'Phone or password wrong!';
        // }

        // user.data.data.user.password = this.form.password;

        this.$store.dispatch("login", {
          user: user.data.data.user,
          token: user.data.data.token
        });

        const voucherCount = await this.$http.get(`${this.$apiTripweWeb}/voucher/count`);
        
        if(voucherCount.data.status == 200){
          this.$store.commit('setVoucherCount', voucherCount.data.total);
        }

        const notif = await this.$http.get(
          `${this.$apiTripweWeb}/notification`
        );
        
        if(notif.data.status == 200){
          this.$store.commit('setNotifications', notif.data.data);
        }

        if (this.$store.state.routeName != "") {
          if (
            this.$store.state.routeParam == "" ||
            this.$store.state.routeParam == {}
          ) {
            this.$router.push({ name: this.$store.state.routeName });
            this.$store.commit("setRouteName", "");
          }
          this.$router.push({
            name: this.$store.state.routeName,
            params: this.$store.state.routeParam,
          });
          this.$store.commit("setRouteName", "");
          this.$store.commit("setRouteParam", "");
        } else {
          this.$router.push({ name: "Home" });
        }
      } catch (error) {
        var msg = error;
        if(error?.response?.status == 400){
          var errData = error.response.data;
          if (errData.status == 400) {
            msg = errData.message;
          }
        }
        if(error?.response?.status == 403){
          var errData = error.response.data;
          if (errData.status == 403) {
            msg = errData.message;
          }
        }
        this.form.password = null;
        this.$toasted.global.error(msg);
      }

      this.$store.commit("setOverlayLoading", false);
    },
  },
};
</script>
